import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/index.less'
import '@/assets/rem.js'

Vue.config.productionTip = false

import VueCanvasPoster from "vue-canvas-poster";
Vue.use(VueCanvasPoster)

import { Uploader } from 'vant';
import 'vant/lib/index.less';
Vue.use(Uploader);

import {Form,FormItem,Button,Input,Dialog,Carousel,CarouselItem} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Button)
Vue.use(Input)
Vue.use(Dialog)
Vue.use(Carousel)
Vue.use(CarouselItem)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
