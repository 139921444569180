<template>
  <div class="home" :style="{ height: homeHeight }">
    <el-form ref="form" :model="form" :rules="rules" label-position="top">
      <el-form-item label="您的姓名" prop="name">
        <el-input
          v-model="form.name"
          @change="checkInput(20)"
          placeholder="请填写您的姓名"
        ></el-input>
        <el-input v-model="form.nameLength" style="display: none"></el-input>
      </el-form-item>
      <el-form-item label="您的班级" prop="class">
        <label slot="label">&nbsp;&nbsp;您的班级</label>
        <el-input
          v-model="form.class"
          placeholder="例：中文 EMBA 第 30 班"
        ></el-input>
      </el-form-item>
      <el-form-item label="您的公司" prop="company">
        <el-input
          v-model="form.company"
          placeholder="请填写您的公司"
        ></el-input>
      </el-form-item>
      <el-form-item label="您的职位" prop="position">
        <el-input
          v-model="form.position"
          placeholder="请填写您的职位"
        ></el-input>
      </el-form-item>
      <el-form-item label="推荐书目" prop="recommended_books">
        <el-input
          v-model="form.recommended_books"
          :maxlength="35"
          placeholder="例：耶路撒冷三千年（字数不超过 35 字）"
        ></el-input>
      </el-form-item>
      <el-form-item label="推荐理由" prop="recommended_reasons">
        <el-input
          type="textarea"
          v-model="form.recommended_reasons"
          :maxlength="100"
          show-word-limit
          :rows="2"
          resize="none"
          placeholder="请填写您推荐此书的理由（字数不超过 100 字）"
        ></el-input>
      </el-form-item>
      <el-button
        type="primary"
        @click="formSubmit"
        style="margin: 0 auto; display: block"
        >进入预览模板</el-button
      >
    </el-form>
    <div class="book">
      <img src="@/assets/7c.png" />
    </div>
    <div class="musicIcon">
      <img src="@/assets/music.svg" v-if="musicPlay" @click="click_music" />
      <img src="@/assets/music2.svg" v-else @click="click_music" />
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      :show-close="false"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      width="80%"
      top="2vh"
    >
      <el-carousel
        trigger="click"
        :height="templateHeight"
        arrow="never"
        :autoplay="false"
        ref="carousel"
        @change="carouselChange"
        indicator-position="none"
      >
        <el-carousel-item>
          <div class="template" :style="{ height: templateHeight }">
            <div class="slogan">
              <img src="@/assets/slogan.png" />
            </div>
            <div class="book">
              <img src="@/assets/7c.png" />
            </div>
            <div class="imgBox">
              <img src="@/assets/imgBox.png" />
            </div>
            <div class="meinv">
              <img :src="clipperImg ? clipperImg : imgUpload" />
            </div>
            <div class="xiangji shadow">
              <van-uploader
                :after-read="read"
                v-if="!hasUp"
                :max-count="1"
                accept="image/*"
              >
                <div>
                  <img src="@/assets/xiangji.png" />
                  <span stype="width: 9.773rem;">上传您的照片或书籍图片</span>
                </div>
              </van-uploader>
              <div class="wenzibg">
                <p>{{ form.class }}</p>
                <p>{{ form.company }}</p>
                <p>{{ form.position }}</p>
              </div>
            </div>
            <div class="bluebg">
              <div class="name">
                <span>{{ form.name }}</span>
                <span style="float: right">为您荐书</span>
              </div>
              <div class="recommend">
                <span style="margin-left: -5px">《</span>
                {{ form.recommended_books }}
                <span>》</span>
              </div>
              <div class="reason">荐书理由</div>
              <div class="reasonContent">{{ form.recommended_reasons }}</div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="template" :style="{ height: templateHeight }">
            <div class="slogan">
              <img src="@/assets/slogan.png" />
            </div>
            <div class="book">
              <img src="@/assets/7c.png" />
            </div>
            <div class="imgBox">
              <img src="@/assets/imgBox.png" />
            </div>
            <div class="meinv">
              <img src="@/assets/meinv.png" />
            </div>
            <div class="xiangji">
              <!-- <img src="@/assets/xiangji.png" />
              <span>上传您的照片或书籍图片</span>-->
              <div class="wenzibg">
                <p>{{ form.class }}</p>
                <p>{{ form.company }}</p>
                <p>{{ form.position }}</p>
              </div>
            </div>
            <div class="bluebg">
              <div class="name">
                <span>{{ form.name }}</span>
                <span style="float: right">为您荐书</span>
              </div>
              <div class="recommend">
                <span style="margin-left: -5px">《</span>
                {{ form.recommended_books }}
                <span>》</span>
              </div>
              <div class="reason">荐书理由</div>
              <div class="reasonContent">{{ form.recommended_reasons }}</div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="template" :style="{ height: templateHeight }">
            <div class="slogan">
              <img src="@/assets/slogan.png" />
            </div>
            <div class="book">
              <img src="@/assets/7c.png" />
            </div>
            <div class="imgBox">
              <img src="@/assets/imgBox.png" />
            </div>
            <div class="meinv">
              <img src="@/assets/book.png" />
            </div>
            <div class="xiangji">
              <!-- <img src="@/assets/xiangji.png" />
              <span>上传您的照片或书籍图片</span>-->
              <div class="wenzibg">
                <p>{{ form.class }}</p>
                <p>{{ form.company }}</p>
                <p>{{ form.position }}</p>
              </div>
            </div>
            <div class="bluebg">
              <div class="name">
                <span>{{ form.name }}</span>
                <span style="float: right">为您荐书</span>
              </div>
              <div class="recommend">
                <span style="margin-left: -5px">《</span>
                {{ form.recommended_books }}
                <span>》</span>
              </div>
              <div class="reason">荐书理由</div>
              <div class="reasonContent">{{ form.recommended_reasons }}</div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="qrcode">
        <img :src="qrcode" />
      </div>
      <div class="close" @click="dialogVisible = false">
        <img src="@/assets/close.svg" />
      </div>
      <button class="shengcheng" @click="onSubmit">生成我的海报</button>
      <div class="brace"></div>
      <button class="prev" @click="$refs['carousel'].prev()">&lt;</button>
      <button class="next" @click="$refs['carousel'].next()">&gt;</button>
    </el-dialog>
    <image-clipper
      ref="clipper"
      v-if="hasUp"
      :img="imgUpload"
      :clipper-img-width="740"
      :clipper-img-height="785"
      @ok="handleUpload"
      @cancel="cancel"
    ></image-clipper>
    <audio :src="musicSrc" loop autoplay ref="au"></audio>
  </div>
</template>

<script>
import QRCode from "qrcode";
import imageClipper from "@/components/vue-imageClipper-master/src/imageClipper.vue";
export default {
  name: "HomeView",
  components: { imageClipper },
  data() {
    return {
      form: {
        class: "",
        name: "",
        company: "",
        position: "",
        recommended_books: "",
        recommended_reasons: "",
      },
      musicPlay: false,
      musicSrc: require("@/assets/607d3ba81ccf8a0f973c04a1.mp3"),
      audio: new Audio(),
      rules: {
        class: [{ message: "请输入您的班级", trigger: "blur" }],
        name: [{ required: true, message: "请输入您的姓名", trigger: "blur" }],
        company: [
          { required: true, message: "请输入您的公司", trigger: "blur" },
        ],
        position: [
          { required: true, message: "请输入您的职位", trigger: "blur" },
        ],
        recommended_books: [
          { required: true, message: "请输入推荐书目", trigger: "blur" },
        ],
        recommended_reasons: [
          { required: true, message: "请输入推荐理由", trigger: "blur" },
        ],
      },
      dialogVisible: false,
      qrcode: "",
      imgUpload: require("@/assets/meinv.png"),
      clipperImg: "",
      hasUp: false,
      carouselIndex: 0,
    };
  },
  computed: {
    homeHeight: {
      get() {
        let width =
          document.documentElement.clientWidth | document.body.clientWidth;
        let size = (width * 896) / 414;
        return size + "px";
      },
      set(value) {},
    },
    templateHeight() {
      return parseFloat(this.homeHeight) * 0.69 + "px";
    },
  },
  methods: {
    click_music() {
      this.musicPlay = !this.musicPlay;
      console.log(this.musicPlay);
      if (this.musicPlay) {
        this.$refs.au.play();
      } else {
        this.$refs.au.pause();
      }
    },
    onSubmit() {
      //计算输入的名字长度多少 ,然后转换px
      //当前字符串的字节数
      let name = this.form.name;
      var strlen = 0;
      for (var i = 0; i < name.length; i++) {
        //判别是中文字符还是英文字符的方法
        if (name.charCodeAt(i) < 27 || name.charCodeAt(i) > 126) {
          //中文和中文字符
          strlen += 2;
        } else {
          strlen++;
        }
      }
      //统计空格数量
      let kongge = name.split(" ").length;
      //一个字节大约3px
      strlen = (strlen + kongge) * 3;
      console.info(strlen);
      this.form.nameLength = strlen;

      let data = {
        carouselIndex: this.carouselIndex,
        qrcode: this.qrcode,
        form: JSON.stringify(this.form),
        imgUpload: this.clipperImg ? this.clipperImg : this.imgUpload,
      };
      this.$router.push({
        path: "/poster",
        query: data,
      });
    },
    creatQrCode() {
      QRCode.toDataURL(window.location.href).then((imgData) => {
        if (imgData) {
          this.qrcode = imgData;
        }
      });
    },
    formSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.dialogVisible = true;
        }
      });
    },
    handleUpload(img) {
      this.clipperImg = img;
      this.hasUp = false;
      console.log(this.clipperImg);
      this.dialogVisible = true;
    },
    cancel() {
      this.imgUpload = require("@/assets/meinv.png");
      this.dialogVisible = true;
      this.hasUp = false;
    },
    read(file) {
      this.imgUpload = file.content;
      this.hasUp = true;
      this.dialogVisible = false;
    },
    carouselChange(index, oldIndex) {
      console.log(index);
      this.carouselIndex = index;
    },
    checkInput(maxLength) {
      let name = this.form.name;

      //当前字符串的字节数
      var strlen = 0;
      for (var i = 0; i < name.length; i++) {
        //判别是中文字符还是英文字符的方法
        if (name.charCodeAt(i) < 27 || name.charCodeAt(i) > 126) {
          //中文和中文字符
          strlen += 2;
        } else {
          strlen++;
        }
      }

      // 自动截掉多余的字符。保留之前的限制内的字符长度
      if (strlen > maxLength) {
        alert(
          "该值长度不能超过" +
            maxLength +
            "字节(1个中文=2个字节,1个英文或数字=1个字节)"
        );
        this.form.name = "";
      }
    },
  },
  mounted() {
    this.creatQrCode();
    // this.$nextTick(() => {
    //   this.$refs.au.play();
    //   console.log(this.$refs.au.paused);
    //   if(this.$refs.au.paused){
    //     this.musicPlay = true;
    //   }
    // });
  },
};

var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?7a53034911627bc6a5bfc487729a95af";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();
</script>

<style lang="less" scoped>
.home {
  width: 100vw;
  background: url("@/assets/indexbg.png") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  top: 0;
  .musicIcon {
    position: fixed;
    top: 80%;
    left: 16px;
  }
  .el-form {
    width: 24.389rem;
    height: 32.5rem;
    position: relative;
    margin: 14.855555555555554rem 0.6rem 0;
    padding: 1.34rem 2.44375rem;
    box-sizing: border-box;
    background: url("@/assets/formbg.png") no-repeat;
    /deep/.el-form-item {
      margin: 1rem 0;
    }
    /deep/ .el-form-item__label {
      color: #fff;
      font-size: 0.8rem;
    }
    /deep/ .el-input__inner {
      border-radius: 6px;
      height: 2.17rem;
      font-size: 0.8rem;
    }
    /deep/ .el-form-item__label {
      font-size: 0.8rem;
    }
    /deep/ .el-form-item__error {
      font-size: 0.5rem;
      margin-top: 0.1rem;
    }
    /deep/ .el-textarea__inner {
      border-radius: 16px;
      height: 4.34rem;
      font-size: 0.8rem;
    }
    .el-button {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
      background: #e0c3ac;
      border-color: #e0c3ac;
      width: 11rem;
      height: 2.4rem;
      border-radius: 0.5rem;
      font-size: 1.1rem;
    }
  }
  .book {
    position: absolute;
    width: 7.246875rem;
    height: 10.14rem;
    top: 9.1rem;
    left: 18.765625rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  /deep/.el-dialog {
    border-radius: 1rem;
    background: url("@/assets/posterbg.png") no-repeat;
    background-size: 100% 100%;
    padding-right: 0rem;
  }
  .template {
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .slogan {
      width: 2.9115rem;
      height: 6.7697rem;
      position: absolute;
      top: 8.21475rem;
      left: 15.6497rem;
    }
    .book {
      width: 6.5rem;
      height: 9rem;
      position: absolute;
      top: 14.5rem;
      left: 13.5rem;
      z-index: 100;
    }
    .imgBox {
      width: 11.8761rem;
      height: 12.5355rem;
      position: absolute;
      top: 8.3rem;
      left: 1.7431rem;
      z-index: 0;
    }
    .bluebg {
      width: 19.8rem;
      height: 13.3rem;
      position: absolute;
      top: 20.4rem;
      /*left: 0.2rem;*/
      z-index: 1;
      background: url("@/assets/bluebg.png") no-repeat;
      background-size: 100% 100%;
      color: #fff;
      .name {
        min-width: 9.73rem;
        max-width: 12.7rem;
        height: 1.448rem;
        line-height: 1.448rem;
        background: #b69882;
        border-radius: 0.19rem;
        position: absolute;
        top: 0;
        left: 0.1rem;
        transform: translate(0, -50%);
        font-size: 0.8rem;
        font-weight: 600;
        padding: 0.3rem 0.728rem 0;
        span:nth-of-type(1) {
          font-size: 1rem;
          padding-left: 5px;
        }
        box-shadow: 4px 4px 6px #303133;
      }
      .recommend {
        position: absolute;
        top: 1.3rem;
        left: 0;
        padding: 0 1rem;
        box-sizing: border-box;
        font-size: 0.9rem;
        font-weight: 600;
        line-height: 1.2rem;
        height: 2.4rem;
        width: 17rem;
      }
      .reason {
        position: absolute;
        top: 4.88rem;
        width: 6.512rem;
        height: 1.448rem;
        line-height: 1.448rem;
        background: #b69882;
        border-radius: 0.19rem;
        font-size: 1rem;
        font-weight: 600;
        padding: 0.3rem 0.728rem 0;
        box-shadow: 4px 4px 6px #303133;
        left: 0.1rem;
      }
      .reasonContent {
        position: absolute;
        height: 3rem;
        bottom: 3.4rem;
        padding: 0 0.7rem;
        font-size: 0.87rem;
        font-weight: 600;
      }
    }
    .meinv {
      width: 11.2rem;
      height: 12.4rem;
      position: absolute;
      top: 8.6rem;
      left: 2.0879rem;
      img {
        border-radius: 0.9577rem 0 0 0;
      }
    }
    .xiangji {
      width: 11.2rem;
      height: 12.4rem;
      position: absolute;
      top: 8.35rem;
      left: 2.0879rem;
      &.shadow {
        background-color: rgba(0, 0, 0, 0.2);
      }
      /deep/.van-uploader {
        position: relative;
        width: 100%;
        height: 100%;
        div {
          position: relative;
          width: 100%;
          height: 100%;
        }
      }
      /deep/.van-uploader__input-wrapper {
        position: relative;
        .van-uploader__input {
          width: 8.773rem;
          height: 3.8rem;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -100%);
        }
        > div {
          img {
            width: 2.2411rem;
            height: 1.7345rem;
            position: absolute;
            top: 48%;
            left: 50%;
            transform: translate(-50%, -200%);
          }
          span {
            width: 8.773rem;
            height: 1.4482rem;
            line-height: 1.4482rem;
            position: absolute;
            text-align: center;
            color: #fff;
            top: 48%;
            left: 50%;
            transform: translate(-50%, -90%);
            border-radius: 0.1915rem;
            background: #dbbda6;
            font-size: 0.5rem;
          }
        }
      }

      .wenzibg {
        width: 8.6197rem;
        height: 4.1659rem;
        position: absolute;
        top: 7rem;
        left: 0;
        padding-left: 16px;
        color: #fff;
        font-size: 0.8rem;
        font-weight: 600;
        background: -webkit-linear-gradient(
          left,
          #2d34577d,
          rgba(255, 255, 255, 0)
        );
        display: flex;
        flex-flow: column;
        justify-content: space-around;
      }
    }
  }
  .qrcode {
    position: absolute;
    bottom: 1.7rem;
    right: 1.4rem;
    width: 4.375rem;
    height: 4.375rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .close {
    text-align: center;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 50%);
    img {
      transform: scale(1.2);
    }
  }
  .shengcheng {
    width: 50%;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 150%);
    background: #3976c3;
    border: none;
    text-align: center;
    padding: 0.5rem 0;
    border-radius: 0.3rem;
    color: #fff;
    font-size: 1rem;
  }
  .brace {
    position: absolute;
    height: 1.5rem;
    width: 1.5rem;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 400%);
  }
  .prev,
  .next {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    border: #ffffff 1px solid;
    text-align: center;
    color: #ffffff;
    transform: translate(0, -50%);
    background: none;
    font-size: 1rem;
    font-weight: 700;
  }
  .prev {
    left: -2.1rem;
  }
  .next {
    right: -2.1rem;
  }
  // 3976c3
}
</style>
