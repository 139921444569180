function rem(){
    let width = document.documentElement.clientWidth | document.body.clientWidth;
    let size = width / 414 * 16;
    console.log(size);
    if(document.documentElement){
        document.documentElement.style.fontSize = size + 'px';
    }else{
        document.body.style.fontSize = size + 'px';
    }
}

window.addEventListener('resize',rem);
rem();